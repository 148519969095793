/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM Common Software                               !
  !     (C) COPYRIGHT OUESTADAM 2024-2025                       !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : oeCarousel.js                                      !
  !  Desc. : Common ReactJs Carousel Management                 !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 03/03/2025                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React, {useEffect, useRef, useState} from 'react';
import Swipe from "react-easy-swipe";

/*
--- Ouestadam products
*/
import "./oeCarousel.css";

/*=============== Persistent data ==============================*/
/*
--- Key numbering
*/
let oaCarouselKeyNumber = 0;

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : OeCarouselJSX                                     !
! Description: JSX Carousel Component                         !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function OeCarousel(paramProps) {
    /*
    --- Values initialisation
    */
    const locData = paramProps.data ? paramProps.data : [];
    const locTime = paramProps.time ? paramProps.time : 2000;
    const locWidth = paramProps.width ? paramProps.width : 600;
    const locHeight = paramProps.height ? paramProps.height : 400;
    const locCaptionStyle = paramProps.captionStyle ? paramProps.captionStyle : {};
    const locSlideNumberStyle = paramProps.slideNumberStyle ? paramProps.slideNumberStyle : {};
    const locRadius = paramProps.radius ? paramProps.radius : "0px";
    const locSlideNumber = (typeof paramProps.slideNumber === "undefined") ? false : paramProps.slideNumber;
    const locStyle = paramProps.style ? paramProps.style : {};
    const locCaptionPosition = paramProps.captionPosition ? paramProps.captionPosition : "none";
    const locDots = (typeof paramProps.dots === "undefined") ? false : paramProps.dots;
    const locAutomatic = (typeof paramProps.automatic === "undefined") ? false : paramProps.automatic;
    const locPauseIconColor = paramProps.pauseIconColor ? paramProps.pauseIconColor : "white";
    const locPauseIconSize = paramProps.pauseIconSize ? paramProps.pauseIconSize : "40px";
    const locSlideBackgroundColor = paramProps.slideBackgroundColor ? paramProps.slideBackgroundColor : "darkgrey";
    const locSlideImageFit = paramProps.slideImageFit ? paramProps.slideImageFit : "contain";
    const locThumbnails = (typeof paramProps.thumbnails === "undefined") ? true : paramProps.thumbnails;
    const locThumbnailHeight = paramProps.thumbnailHeight ? paramProps.thumbnailHeight : "50px";
    const locShowNavBtn = (typeof paramProps.showNavBtn === "undefined") ? true : paramProps.showNavBtn;
    const locFadeNavBtn = (typeof paramProps.fadeNavBtn === "undefined") ? false : paramProps.fadeNavBtn;
    const locStartSlideNb = paramProps.startSlideNb ? paramProps.startSlideNb : 0;
    const locRoundRobin = (typeof paramProps.roundRobin === "undefined") ? false : paramProps.roundRobin;

    /*
    --- Initialize Ref and State
    */
    const locRef = useRef(null);
    const [locSlide, locSetSlide_f] = useState(locStartSlideNb);
    const [locIsPaused, locSetIsPaused_f] = useState(false);
    const [locChange, locSetChange_f] = useState(false);

    /*
    --- Increment Key number
    */
    oaCarouselKeyNumber++;

    /*
    --- Function for changing Slide
    */
    const locAddSlide_f = (paramNb) => {
        if (locSlide + paramNb >= locData.length) {
            if (locRoundRobin) locSetSlide_f(0); else locSetSlide_f(locData.length - 1);
        } else if (locSlide + paramNb < 0) {
            if (locRoundRobin) locSetSlide_f(locData.length - 1); else locSetSlide_f(0);
        } else locSetSlide_f(locSlide + paramNb);
    };

    /*
    --- Function for last Slide
    */
    const locLastSlide_f = () => {
        locSetSlide_f(locData.length - 1);
    };

    /*
    --- Function for last Slide
    */
    const locFirstSlide_f = () => {
        locSetSlide_f(0);
    };

    /*
    --- Start the automatic change of slide
    */
    useEffect(() => {
        if (locAutomatic) {
            let locIndex = locSlide;
            const locInterval = setInterval(
                () => {
                    if (!locIsPaused) {
                        locSetSlide_f(locIndex);
                        locIndex++;
                        if (locIndex >= locData.length) locIndex = 0;
                        if (locIndex < 0) locIndex = locData.length - 1;
                    }
                }, locTime);
            return () => {
                clearInterval(locInterval);
            };
        }
    }, [locIsPaused, locChange, locAutomatic, locData, locTime, locSlide]);

    /*
    --- Function for scrolling to
    */
    function locScrollTo_f(paramElement) {
        const locElementLeft = paramElement.offsetLeft + paramElement.offsetWidth;
        const locElementParentLeft = paramElement.parentNode.offsetLeft + paramElement.parentNode.offsetWidth;
        /*
        --- check if element not in view
        */
        if (locElementLeft >= locElementParentLeft + paramElement.parentNode.scrollLeft) {
            paramElement.parentNode.scroll({left: locElementLeft - locElementParentLeft, behavior: "smooth"});
        } else if (locElementLeft <= paramElement.parentNode.offsetLeft + paramElement.parentNode.scrollLeft) {
            paramElement.parentNode.scroll({
                left: paramElement.offsetLeft - paramElement.parentNode.offsetLeft,
                behavior: "smooth",
            });
        }
    }

    /*
    Listens to slide state changes
    */
    useEffect(() => {
        let locSlides = document.getElementsByClassName("carousel-item");
        let locDots = document.getElementsByClassName("dot");
        let locSlideIndex = locSlide;
        locRef.current.focus();
        let locI;
        for (locI = 0; locI < locData.length; locI++) {
            locSlides[locI].style.display = "none";
        }
        for (locI = 0; locI < locDots.length; locI++) {
            locDots[locI].className = locDots[locI].className.replace(" active", "");
        }
        /*
        --- If thumbnails are enabled
        */
        if (locThumbnails) {
            let locThumbnailsArray = document.getElementsByClassName("thumbnail");
            for (locI = 0; locI < locThumbnailsArray.length; locI++) {
                locThumbnailsArray[locI].className = locThumbnailsArray[locI].className.replace(
                    " active-thumbnail",
                    ""
                );
            }
            if (locThumbnailsArray[locSlideIndex] !== undefined)
                locThumbnailsArray[locSlideIndex].className += " active-thumbnail";
            locScrollTo_f(document.getElementById(`thumbnail-${locSlideIndex}`));
        }
        if (locSlides[locSlideIndex] !== undefined) {
            locSlides[locSlideIndex].style.display = "block";
            locSlides[locSlideIndex].focus();
        }
        if (locDots[locSlideIndex] !== undefined) locDots[locSlideIndex].className += " active";
    }, [locSlide, locIsPaused, locData, locThumbnails]);
    /*
    --- Navigation button style according the Rotation and Fade argument
    */
    const locPrevNavButtonStyle = {};
    if ((locSlide < 1) && (!locRoundRobin)) locPrevNavButtonStyle["display"] = "none";
    if (locFadeNavBtn) {
        locPrevNavButtonStyle["animation-name"] = "prevnext";
        locPrevNavButtonStyle["animation-duration"] = "4s";
        locPrevNavButtonStyle["opacity"] = "0";
    }
    const locNextNavButtonStyle = {};
    if ((locSlide >= (locData.length - 1)) && (!locRoundRobin)) locNextNavButtonStyle["display"] = "none";
    if (locFadeNavBtn) {
        locNextNavButtonStyle["animation-name"] = "prevnext";
        locNextNavButtonStyle["animation-duration"] = "4s";
        locNextNavButtonStyle["opacity"] = "0";
    }
    /*
    --- Return the Carousel
    */
    return (
        <div style={locStyle} className="box"
             tabIndex={-1}
             ref={locRef}
             onKeyDown={(paramEvent) => {
                 switch (paramEvent.key) {
                     case "ArrowRight" :
                     case "PageUp" :
                         locAddSlide_f(1);
                         locSetChange_f(!locChange);
                         break;
                     case "End" :
                         locLastSlide_f();
                         locSetChange_f(!locChange);
                         break;
                     case "ArrowLeft" :
                     case "PageDown" :
                         locAddSlide_f(-1);
                         locSetChange_f(!locChange);
                         break;
                     case "Home" :
                         locFirstSlide_f();
                         locSetChange_f(!locChange);
                         break;
                     default:
                         break;
                 }
             }}>
            <div style={{minWidth: locWidth + "px", maxHeight: locHeight + "px"}}>
                <Swipe
                    onSwipeRight={() => {
                        locAddSlide_f(-1);
                        locSetChange_f(!locChange);
                        return true;
                    }}
                    onSwipeUp={() => {
                        locFirstSlide_f();
                        locSetChange_f(!locChange);
                        return true;
                    }}
                    onSwipeLeft={() => {
                        locAddSlide_f(1);
                        locSetChange_f(!locChange);
                        return true;
                    }}
                    onSwipeDown={() => {
                        locLastSlide_f();
                        locSetChange_f(!locChange);
                        return true;
                    }}
                    tolerance={50}
                >
                    <div
                        className="carousel-container"
                        style={{
                            maxWidth: (locWidth + 10) + "px",
                            display: "grid",
                            justifyContent: "center",
                            alignItems: "center",
                            maxHeight: (locHeight + 10) + "px",
                            backgroundColor: locSlideBackgroundColor,
                            borderRadius: locRadius,
                            paddingTop: "10px",
                            paddingBottom: "10px"
                        }}>
                        {locData.map((paramItem, paramIndex) => {
                            return (
                                <div
                                    className="carousel-item fade"
                                    style={{maxWidth: locWidth + "px", maxHeight: locHeight + "px"}}
                                    onMouseDown={() => {
                                        locAutomatic && locSetIsPaused_f(true);
                                    }}
                                    onMouseUp={() => {
                                        locAutomatic && locSetIsPaused_f(false);
                                    }}
                                    onMouseLeave={() => {
                                        locAutomatic && locSetIsPaused_f(false);
                                    }}
                                    onTouchStart={() => {
                                        locAutomatic && locSetIsPaused_f(true);
                                    }}
                                    onTouchEnd={() => {
                                        locAutomatic && locSetIsPaused_f(false);
                                    }}
                                    key={paramIndex}>
                                    {locSlideNumber && (
                                        <div className="slide-number" style={locSlideNumberStyle}>
                                            {paramIndex + 1} / {locData.length}
                                        </div>
                                    )}
                                    <img
                                        src={paramItem.image}
                                        alt={paramItem.caption}
                                        className="carousel-image"
                                        style={{
                                            borderRadius: locRadius,
                                            objectFit: locSlideImageFit
                                        }}/>
                                    {locIsPaused && (
                                        <div
                                            className="pause-icon pause"
                                            style={{
                                                color: locPauseIconColor,
                                                fontSize: locPauseIconSize,
                                            }}>
                                            II
                                        </div>
                                    )}
                                    <div
                                        className={`carousel-caption-${locCaptionPosition}`}
                                        style={locCaptionStyle}
                                        dangerouslySetInnerHTML={{__html: paramItem.caption}}
                                    ></div>
                                </div>
                            );
                        })}
                        {locShowNavBtn && (
                            <div className="prev"
                                 key={"prev" + oaCarouselKeyNumber}
                                 onClick={() => {
                                     locAddSlide_f(-1);
                                     locSetChange_f(!locChange);
                                 }}
                                 style={locPrevNavButtonStyle}
                            >
                                &#x23F4;
                            </div>
                        )}
                        {locShowNavBtn && (
                            <div className="first"
                                 key={"first" + oaCarouselKeyNumber}
                                 onClick={() => {
                                     locFirstSlide_f();
                                     locSetChange_f(!locChange);
                                 }}
                                 style={locPrevNavButtonStyle}
                            >
                                &#x23EE;
                            </div>
                        )}
                        {locShowNavBtn && (
                            <div
                                className="next"
                                key={"next" + oaCarouselKeyNumber}
                                onClick={() => {
                                    locAddSlide_f(1);
                                    locSetChange_f(!locChange);
                                }}
                                style={locNextNavButtonStyle}
                            >
                                &#x23F5;
                            </div>
                        )}
                        {locShowNavBtn && (
                            <div
                                className="last"
                                key={"last" + oaCarouselKeyNumber}
                                onClick={() => {
                                    locLastSlide_f();
                                    locSetChange_f(!locChange);
                                }}
                                style={locNextNavButtonStyle}
                            >
                                &#x23ED;
                            </div>
                        )}
                        {locDots && (
                            <div className="dots">
                                {locData.map((paramItem, paramIndex) => {
                                    return (
                                        <span
                                            className="dot"
                                            key={paramIndex}
                                            onClick={() => {
                                                locSetSlide_f(paramIndex);
                                                locSetChange_f(!locChange);
                                            }}
                                        ></span>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </Swipe>
            </div>
            {
                locThumbnails && (
                    <div
                        className="thumbnails"
                        id="thumbnail-div"
                        style={{maxWidth: locWidth, display: "flex"}}
                    >
                        {locData.map((paramItem, paramIndex) => {
                            return (
                                <img
                                    height={locThumbnailHeight}
                                    src={paramItem.image}
                                    alt={paramItem.caption}
                                    className="thumbnail"
                                    id={`thumbnail-${paramIndex}`}
                                    key={paramIndex}
                                    onClick={() => {
                                        locSetSlide_f(paramIndex);
                                        locSetChange_f(!locChange);
                                    }}
                                />
                            );
                        })}
                    </div>
                )
            }
        </div>
    )
}